/* Only demo */
@media (max-width: 1000px) {
  .welcome-message {
    display: none;
  }
}

.lightBoxGallery {
  text-align: center;
}

.lightBoxGallery img {
  margin: 5px;
}