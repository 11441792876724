/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.2
 *
*/
// Google Fonts
@import url("//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&lang=en");
// Variables, Mixins
@import "base/_variables";
@import "base/_mixins";
// INSPINIA Theme Elements
@import "base/_typography";
@import "base/_navigation";
@import "base/_top_navigation";
@import "base/_buttons";
@import "base/_badgets_labels";
@import "base/_elements";
@import "base/_sidebar";
@import "base/_base";
@import "base/_pages";
@import "base/_chat";
@import "base/_metismenu";
@import "base/_spinners";
// Landing page
@import "base/_landing";
// RTL Support
@import "base/_rtl";
// For demo only - config box style
@import "base/_theme-config";
// INSPINIA Skins
@import "base/_skins";
@import "base/_md-skin";
// Media query style
@import "base/_media";
// Custom style
// Your custom style to override base style
@import "base/_custom";



