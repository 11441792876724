.b-l {
  border-left: 1px solid #e7eaec;
}

td.vam,
th.vam {
  vertical-align: middle !important;
}

td.vab,
th.vab {
  vertical-align: bottom !important;
}

td.vam_tc,
th.vam_tc {
  vertical-align: middle !important;
  text-align: center !important;
}

td.vab_tc,
th.vab_tc {
  vertical-align: bottom !important;
  text-align: center !important;
}

td.vam_tc input,
th.vam_tc input {
  margin: 0 auto;
}

.padding-1 {
  padding: 1px !important;
}

.padding-2 {
  padding: 2px !important;
}

.padding-3 {
  padding: 3px !important;
}

.padding-4 {
  padding: 4px !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-6 {
  padding: 6px !important;
}

.padding-7 {
  padding: 7px !important;
}

.padding-8 {
  padding: 8px !important;
}

.padding-9 {
  padding: 9px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-11 {
  padding: 11px !important;
}

.padding-12 {
  padding: 12px !important;
}

.padding-13 {
  padding: 13px !important;
}

.padding-14 {
  padding: 14px !important;
}

.padding-15 {
  padding: 15px !important;
}

.padding-16 {
  padding: 16px !important;
}

.padding-17 {
  padding: 17px !important;
}

.padding-18 {
  padding: 18px !important;
}

.padding-19 {
  padding: 19px !important;
}

.padding-20 {
  padding: 20px !important;
}

.padding-l-1 {
  padding-left: 1px !important;
}

.padding-l-2 {
  padding-left: 2px !important;
}

.padding-l-3 {
  padding-left: 3px !important;
}

.padding-l-4 {
  padding-left: 4px !important;
}

.padding-l-5 {
  padding-left: 5px !important;
}

.padding-l-6 {
  padding-left: 6px !important;
}

.padding-l-7 {
  padding-left: 7px !important;
}

.padding-l-8 {
  padding-left: 8px !important;
}

.padding-l-9 {
  padding-left: 9px !important;
}

.padding-l-10 {
  padding-left: 10px !important;
}

.padding-l-11 {
  padding-left: 11px !important;
}

.padding-l-12 {
  padding-left: 12px !important;
}

.padding-l-13 {
  padding-left: 13px !important;
}

.padding-l-14 {
  padding-left: 14px !important;
}

.padding-l-15 {
  padding-left: 15px !important;
}

.padding-l-16 {
  padding-left: 16px !important;
}

.padding-l-17 {
  padding-left: 17px !important;
}

.padding-l-18 {
  padding-left: 18px !important;
}

.padding-l-19 {
  padding-left: 19px !important;
}

.padding-l-20 {
  padding-left: 20px !important;
}

.padding-r-1 {
  padding-right: 1px !important;
}

.padding-r-2 {
  padding-right: 2px !important;
}

.padding-r-3 {
  padding-right: 3px !important;
}

.padding-r-4 {
  padding-right: 4px !important;
}

.padding-r-5 {
  padding-right: 5px !important;
}

.padding-r-6 {
  padding-right: 6px !important;
}

.padding-r-7 {
  padding-right: 7px !important;
}

.padding-r-8 {
  padding-right: 8px !important;
}

.padding-r-9 {
  padding-right: 9px !important;
}

.padding-r-10 {
  padding-right: 10px !important;
}

.padding-r-11 {
  padding-right: 11px !important;
}

.padding-r-12 {
  padding-right: 12px !important;
}

.padding-r-13 {
  padding-right: 13px !important;
}

.padding-r-14 {
  padding-right: 14px !important;
}

.padding-r-15 {
  padding-right: 15px !important;
}

.padding-r-16 {
  padding-right: 16px !important;
}

.padding-r-17 {
  padding-right: 17px !important;
}

.padding-r-18 {
  padding-right: 18px !important;
}

.padding-r-19 {
  padding-right: 19px !important;
}

.padding-r-20 {
  padding-right: 20px !important;
}

input.width-10, select.width-10 {
  width: 10px !important;
}

input.width-20, select.width-20 {
  width: 20px !important;
}

input.width-30, select.width-30 {
  width: 30px !important;
}

input.width-40, select.width-40 {
  width: 40px !important;
}

input.width-50, select.width-50 {
  width: 50px !important;
}

input.width-60, select.width-60 {
  width: 60px !important;
}

input.width-70, select.width-70 {
  width: 70px !important;
}

input.width-80, select.width-80 {
  width: 80px !important;
}

input.width-90, select.width-90 {
  width: 90px !important;
}

input.width-100, select.width-100 {
  width: 100px !important;
}

input.width-110, select.width-110 {
  width: 110px !important;
}

input.width-120, select.width-120 {
  width: 120px !important;
}

input.width-130, select.width-130 {
  width: 130px !important;
}

input.width-140, select.width-140 {
  width: 140px !important;
}

input.width-150, select.width-150 {
  width: 150px !important;
}

input.item-inline {
  display: inline-block !important;
}

/*Datatables*/
.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 80px;
  margin-left: -125px;
  margin-top: -15px;
  padding: 15px 0 30px 0;
  border: 1px solid #ddd;
  text-align: center;
  color: #444;
  font-size: 14px;
  background: url("../img/loading_bar.gif") no-repeat center 38px #fff;
  box-shadow: 2px 2px 5px #444;
  -moz-box-shadow: 2px 2px 5px #444;
  -webkit-box-shadow: 2px 2px 5px #444;
  z-index: 9999;
}

table.table td {
  vertical-align: middle !important;
}

/*Wizard*/
.tabsWizardContent {
  width: 100% !important;
  position: relative !important;
  padding: 0 !important;
}

.wizard > .steps a {
  background: #6FD1BD none repeat scroll 0% 0%;
  color: #FFF;
}

.wizard > .content > .body > .tabs-container ul {
  list-style: none !important;
}

#bid_editor_content {
  display: none;
}

/*Datatable*/

table.dataTableItemRowStyle tbody tr td {
  padding: 3px !important;
  font-size: 15px !important;
}

table.dataTableItemRowStyle tbody tr td.font-size-14 {
  font-size: 14px !important;
}

/*Input*/

.title-form-control {
  height: 30px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  top: -5px;
  position: relative;
}

.link-danger > td a {
  color: #ed5565 !important;
  text-decoration: underline !important;
}

.link-warning > td a {
  color: #f8ac59 !important;
  text-decoration: underline !important;
}

.text-success {
  color: #17987E !important;
}

.link-success td a {
  color: #17987E !important;
  text-decoration: underline !important;
}

.link-primary > td a {
  color: inherit !important;
  text-decoration: underline !important;
}

.text-bold,
.text-bold > td a {
  font-weight: bold !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

td.details-control {
  background: url('../img/plus.png') no-repeat center center;
  cursor: pointer;
  background-size: 20px;
}

tr.shown td.details-control {
  background: url('../img/minus.png') no-repeat center center;
  cursor: pointer;
  background-size: 20px;
}

div#attachedModal table tr td strong {
  word-break: break-all;
}

#delivery_table {
  thead {
    tr {
      th {
        background-color: #7c1818;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }

  td {
    min-width: 198px;
  }

  td[data-dayname="Szo."],
  td[data-dayname="Vas."]{
    background-color: #f5f5f5;
  }

  td.creative {
    background-color: #FF5555;
    color: #ffffff;
    border-right: 3px solid #7c1818;
  }

  ul.eventlist{
    -webkit-padding-start: 0;
    -moz-padding-start: 0;
    min-height: 100px;

    li{
      list-style: none;
    }


    li.event-item div, div.ui-state-highlight {
      max-width: 230px;
      min-height: 50px;
      text-align: left;
      font-weight: bold;
      padding-top: 5px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 11px;
    }


    li.event-item div span:hover {
      text-decoration: underline;
    }

    li.event-item div.selected{
      border-left: 4px solid #ffd00d !important;
    }

    li.event-item div.red {
      background-color: #FA5252;
      border-left: 4px solid #E21313;
      color: #ffffff;
    }

    li.event-item div.blue {
      background-color: #5199d7;
      border-left: 4px solid #216fb2;
      color: #ffffff;
    }

    li.event-item div.light-blue {
      background-color: #C5F6FA;
      border-left: 4px solid #67E7F2;
      color: #000000;
    }

    li.event-item div.home {
      background-color: #fcc373;
      border-left: 4px solid #fc990b;
      color: #000000;
    }

    li.event-item div.closed {
      background-color: transparent;
      border: 1px solid #FDA9A9;
      border-left: 4px solid #FDA9A9;
      color: #000000;
      text-decoration: line-through;
      color: #938f8f;
    }

    li.event-item div.other {
      background-color: #d57ee8;
      border-left: 4px solid #69177b;
      color: #ffffff;
    }
  }

  .ui-state-default,.div.ui-state-highlight{
    min-height: 50px;
    margin: 5px 0;
  }

  tbody {
    tr {
      td {
        vertical-align: middle;
        border: 1px solid #e7eaec;
        vertical-align: top !important;
      }
    }
  }

  tbody {
    tr {
      td.delivery_container {
        min-height: 45px !important;
      }
    }
  }
}

.table_delivery_wrapper {
  overflow-x: scroll;
  position: relative;

  table {
    width: 100%;
    margin-bottom: 20px;
  }
  table tbody tr td.delivery-firstColumn {
    background-color: #7c1818;
    color: #ffffff;
  }
  table tbody tr td.delivery-firstColumn_scrolled_content {
    background-color: #EC4758;
    color: #ffffff;
  }
}

.pointer {
  cursor: pointer;
}

.popover {
  max-width: 450px !important;
}

.ui-autocomplete {
  z-index: 99999999 !important;
}

.datepicker {
  border-radius: 0 !important;
}

tr.group {
  background-color: antiquewhite;
  font-size: 18px;
}