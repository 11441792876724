// Bordered & Pulled
// -------------------------

.#{$fa-css-prefix}-border {
  padding: .2em .25em .15em;
  border: solid .08em $fa-border-color;
  border-radius: .1em;
}

.#{$fa-css-prefix}-pull-left {
  float: left;
}

.#{$fa-css-prefix}-pull-right {
  float: right;
}

.#{$fa-css-prefix} {
  &.#{$fa-css-prefix}-pull-left {
    margin-right: .3em;
  }
  &.#{$fa-css-prefix}-pull-right {
    margin-left: .3em;
  }
}

/* Deprecated as of 4.4.0 */
.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.#{$fa-css-prefix} {
  &.pull-left {
    margin-right: .3em;
  }
  &.pull-right {
    margin-left: .3em;
  }
}
